export const main = {
  Name: 'main',
  Landing: {},
  Quiz: {
    quizFlow: [
      {
        question: 'How old is your dog?',
        questionCode: 'age',
        image: '/static/images/1.svg',
        answers: [
          '0-6 months',
          '7-12 months',
          '1-2 years',
          '2-5 years',
          'More than 5 years',
        ],
        type: 'question',
      },
      {
        question: 'What breed is your dog?',
        questionCode: 'breed',
        image: '/static/images/2.svg',
        answers: [
          'Mixed Breed',
          'Labrador Retriever',
          'Golden Retriever',
          'Siberian Husky',
          'American Pit Bull Terrier',
          'French Bulldog',
          'German Shepherd',
          'Other',
        ],
        type: 'question',
      },

      {
        question: 'Which commands has your dog already learned?',
        questionCode: 'commands_learned',
        image: '/static/images/3.svg',
        subtitle: 'Check all that apply to you:',
        answers: [
          'Stay',
          'Drop it',
          'Give',
          'Roll over',
          'Down',
          'Play dead',
          'Come',
          'Beg',
          'Sit',
          '🚫 Null',
        ],
        type: 'question',
        isMultiSelect: true,
      },

      {
        type: 'info',
        image: '/static/images/badge.png',
        heading: 'Fantastic! Let’s draw up the training plan for your dog',
        description:
          "Please, don't be afraid to tell us the truth. It helps us to best customize your plan.",
        button: 'Continue',
      },

      {
        question: 'Does your dog get nervous in new places?',
        questionCode: 'dog_nervous',
        image: '/static/images/4.svg',
        answers: ['✅ Yes', '🤔 So-so', '🚫 No'],
        type: 'question',
      },

      {
        question: 'Does your dog get anxious when they see strangers?',
        questionCode: 'dog_anxious_with_strangers',
        image: '/static/images/5.svg',
        answers: ['✅ Yes', '🤔 So-so', '🚫 No'],
        type: 'question',
      },

      {
        question: 'Does your dog get stressed when at home alone?',
        questionCode: 'dog_gets_stressed_alone',
        image: '/static/images/6.svg',
        answers: ['✅ Yes', '🤔 So-so', '🚫 No'],
        type: 'question',
      },

      {
        question: 'How often do you leave your dog home alone?',
        questionCode: 'how_often_dog_alone',
        image: '/static/images/7.svg',
        answers: [
          '🏠 Every day',
          '🗓️ Several times a week',
          '📅 Several times a month',
          '🦮 Never',
        ],
        type: 'question',
      },

      {
        question: 'How many times a day does your dog get a walk?',
        questionCode: 'how_often_walks',
        image: '/static/images/8.svg',
        answers: ['1', '2', '3+'],
        type: 'question',
      },

      {
        question:
          'Is your dog okay with having their nails clipped, being bathed, and having their ears cleaned?',
        questionCode: 'is_okay_with_bath',
        image: '/static/images/9.svg',
        answers: ['✅ Yes', '🤔 Not always', '🚫 No'],
        type: 'question',
      },

      {
        question: 'Choose your main points of concern',
        subtitle: 'Check all that might apply to you:',
        questionCode: 'concerns',
        image: '/static/images/10.svg',
        answers: [
          'Chewing',
          'Barking',
          'Digging',
          'Food stealing',
          'Biting',
          'Pulling on the leash',
        ],
        type: 'question',
        isMultiSelect: true,
      },

      {
        question: 'Do you worry that your dog might end up in a dangerous situation?',
        questionCode: 'dangerous_situation',
        image: '/static/images/11.svg',
        answers: ['✅ Yes', '🚫 No'],
        type: 'question',
      },

      {
        question: 'Do you worry your dog might eat something bad and end up at the vet?',
        questionCode: 'eat_something_bad',
        image: '/static/images/12.svg',
        answers: ['✅ Yes', '🚫 No'],
        type: 'question',
      },

      {
        question: 'Are you afraid your dog will get lost?',
        questionCode: 'dog_lost',
        image: '/static/images/13.svg',
        answers: ['✅ Yes', '🚫 No'],
        type: 'question',
      },

      {
        type: 'info',
        image: '/static/images/14.svg',
        heading: 'Your feelings are totally normal.',
        description:
          'Our app Dogster will help you connect with your dog and make training easier and more effective.',
        button: 'Continue',
      },

      {
        question: 'Does your dog come when called?',
        subtitle:
          'If the dog comes when called, the probability of getting lost is reduced to 0.',
        questionCode: 'does_dog_come_when_called',
        image: '/static/images/15.svg',
        answers: ['✅ Yes', '🚫 No'],
        type: 'question',
      },

      {
        question: 'Does it annoy you when your dog chews, jumps, or bites?',
        questionCode: 'does_biting_annoy',
        image: '/static/images/16.svg',
        answers: ['✅ Yes', '🚫 No'],
        type: 'question',
      },

      {
        question: 'Have you found dog training harder than you expected?',
        questionCode: 'dog_training_harder_than_expected',
        image: '/static/images/17.svg',
        answers: ['✅ Yes', '🤔 So-so', '🚫 No'],
        type: 'question',
      },

      {
        question: 'What is your main goal?',
        subtitle: 'Check all that might apply to you:',
        questionCode: 'main_goal',
        image: '/static/images/18.svg',
        answers: [
          'Solve behavior problems',
          'Learn new tricks',
          'Understand dog psychology',
          'Other',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'How much time during the day can you spend training?',
        subtitle: '',
        questionCode: 'time',
        image: '/static/images/1.svg',
        answers: ['⏳ 5-10 minutes', '⏰ 15-20 minutes', '🚀 20-30 minutes'],
        type: 'question',
      },
      {
        type: 'info',
        heading: "The last plan you’ll ever need to fix your dog's behavior challenges",
        description:
          "<div>We predict you’ll increase your dog's obedience level by<br /><strong>{date}</strong><br /><div style='width:75%; margin: 0px auto;'><img style='min-width:100%; margin-bottom: 0px;' src='/static/images/chart.svg' /><div style='display: flex; justify-content: space-between; padding: 0px 5%;'><span>{month1}</span><span>{month2}</span><span>{month3}</span><span>{month4}</span></div></div></div>",
        button: 'Continue',
      },
      {
        type: 'email',
      },
    ],
  },
  GetStarted: {
    description:
      "We've created a personalized plan just for you to make your dog follow your lead like a pro!",
    button: 'Get my plan',
  },
  Summary: {
    heading: 'BASED ON YOUR ANSWERS, WE RECOMMEND A:',
    resultsTitle: 'Inner critic score',
    suggestedPlan: 'Parenting empowerment',
    issue: 'parental excellence',
    summary: [
      {
        heading: 'Rejection anxiety',
        description:
          'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
      },
      {
        heading: 'Overwhelming self-doubt',
        description:
          'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Powerful inner critic',
        description:
          'You frequently criticize yourself harshly for any deviations from your plans.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Self-Improvement',
    promise: 'boost self-esteem',
    subtitle:
      'Fast-track your workflow, boost your sales, streamline your operations and and stay ahead of the curve.',
    benefits: [
      {
        heading: 'Achieve Peace of Mind',
        description:
          'Experience less stress and more happiness with a well-trained dog by your side.',
      },
      {
        heading: 'Joyful Companionship',
        description:
          'Enjoy happier moments together with your obedient and contented dog.',
      },
      {
        heading: 'Gain Confidence',
        description:
          'Feel more confident as a dog owner with improved control and communication.',
      },
      {
        heading: 'Cultivate a Stronger Bond',
        description: 'Forge a deeper, unbreakable connection with your furry friend.',
      },
      {
        heading: 'Take Pride in Progress',
        description:
          "Take pride in your dog's growth and development with every training session.",
      },
      {
        heading: 'Hassle-Free Happiness',
        description:
          'Simplify your life and embrace the happiness of a well-behaved dog.',
      },
    ],

    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/cash.png',
        heading: 'Save time and money',
        description:
          'Streamline your workflow and optimize your resources. Our efficient tools and automated processes will save you valuable time and money, allowing you to focus on what truly matters for your business growth.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Increase conversion rate',
        description:
          'Experience a significant increase in your conversion rate. Our proven strategies and techniques will optimize your marketing efforts, resulting in higher conversion rates and greater business success.',
      },
      {
        image: '/static/icons/bed.png',
        heading: 'Boosted email revenue',
        description:
          'Unlock the potential of your email marketing campaigns. Our AI-based insights and personalized recommendations will help you craft compelling emails that engage your audience and drive revenue growth.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Ad concepts and angles',
        description:
          'Stand out from the competition. Our AI-powered platform generates innovative ad concepts and angles that capture attention, drive engagement, and ultimately boost your advertising effectiveness.',
      },
    ],
  },
};
