import { main } from './Funnels/main';
import { trial } from './Funnels/trial';
import { IProduct } from '../../types';
import { findProductBySku } from '../../helpers/products';

export const Funnels = {
  main,
  trial,
  default: main,
};

export const FunnelRoutes = {
  HOME: '/',
  QUIZ: '/quiz',
  CHECKING: '/checking',
  SUMMARY: '/summary',
  PURCHASE_PLAN: '/purchase-plan',
  PURCHASE_TRIAL: '/purchase-trial',
  THANK_YOU: '/thank-you',
};

export const UpsellsOrder = {
  default: [],
};

export const RoutesOrder = {
  default: [
    FunnelRoutes.HOME,
    FunnelRoutes.QUIZ,
    FunnelRoutes.CHECKING,
    FunnelRoutes.SUMMARY,
    FunnelRoutes.PURCHASE_PLAN,
    FunnelRoutes.THANK_YOU,
  ],
  trial: [
    FunnelRoutes.HOME,
    FunnelRoutes.QUIZ,
    FunnelRoutes.CHECKING,
    FunnelRoutes.PURCHASE_TRIAL,
    FunnelRoutes.THANK_YOU,
  ],
};

export const ProductSkus = {
  sixMonthPlan: 'sixMonthPlan',
  threeMonthPlan: 'threeMonthPlan',
  oneMonthPlan: 'oneMonthPlan',
  threeMonthsTrialPlan: 'threeMonthsTrialPlan',
};

export const getProducts = (products: IProduct[]) => ({
  sixMonthPlan: findProductBySku(products, ProductSkus.sixMonthPlan),
  threeMonthPlan: findProductBySku(products, ProductSkus.threeMonthPlan),
  oneMonthPlan: findProductBySku(products, ProductSkus.oneMonthPlan),
  threeMonthsTrialPlan: findProductBySku(
    products,
    process.env['REACT_APP_THREE_MONTHS_TRIAL_PLAN'] || ProductSkus.threeMonthsTrialPlan,
  ),
});

export const ProjectName = 'Dogster';
